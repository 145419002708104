import { useEffect, useRef } from "react";
import { ModalViewSpeechTreeProps } from "../../model";
import FCDTModal from "components/modal-notification/fcdt-modal";
import * as d3 from "d3";
import Button from "components/buttons/fcdt-button";
import Title from "components/fcdt-title/fcdt-title";
import style from "./style/modal-speech-tree.module.scss";
import { Node, SpeechTreeValue } from "pages/landing-page/model";

interface Link extends SpeechTreeValue {
  index: number;
}

const ModalSpeechTree = (props: ModalViewSpeechTreeProps) => {
  const { open, handleCloseModal, dataSpeechTree } = props;

  const links: Link[] = dataSpeechTree.map((item) => {
    return { ...item, index: 0 };
  });

  const sources: (string | Node)[] = [];
  links.forEach((item) => {
    sources.push(item.source);
    sources.push(item.target);
  });
  const newData = Array.from(new Set(sources));

  const nodes = newData.map((item, index) => {
    return {
      id: item,
      group: index + 1,
      label: item,
      principal: false,
    };
  }) as Node[];

  const svgRef = useRef<SVGSVGElement>(null);

  const handleGenerateSpeechTree = () => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", "100%")
      .attr("height", "400px");

    const width = parseInt(svg.style("width"));
    const height = parseInt(svg.style("height"));

    const data = {
      nodes,
      links,
    };

    svg.selectAll("*").remove(); // Limpiar elementos SVG anteriores

    // Define arrow markers for different directions
    const defs = svg.append("defs");

    defs
      .append("marker")
      .attr("id", "arrowhead-forward")
      .attr("viewBox", "-0 -5 10 10")
      .attr("refX", 20)
      .attr("refY", 0)
      .attr("orient", "auto")
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .append("svg:path")
      .attr("d", "M 0,-5 L 10,0 L 0,5")
      .attr("fill", "#999");

    defs
      .append("marker")
      .attr("id", "arrowhead-reverse")
      .attr("viewBox", "-0 -5 10 10")
      .attr("refX", -10)
      .attr("refY", 0)
      .attr("orient", "auto")
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .append("svg:path")
      .attr("d", "M 10,-5 L 0,0 L 10,5")
      .attr("fill", "#999");

    defs
      .append("marker")
      .attr("id", "arrowhead-both-forward")
      .attr("viewBox", "-0 -5 10 10")
      .attr("refX", -10)
      .attr("refY", 0)
      .attr("orient", "auto")
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .append("svg:path")
      .attr("d", "M 0,-5 L 10,0 L 0,5")
      .attr("fill", "#999");

    const simulation = d3
      .forceSimulation<Node>(data.nodes)
      .force(
        "link",
        d3
          .forceLink<Node, Link>(data.links)
          .id((d) => d.id)
          .distance(100)
      )
      .force("charge", d3.forceManyBody().strength(-3000))
      .force("center", d3.forceCenter(width / 2, height / 2));

    const link = svg
      .append("g")
      .attr("class", "links")
      .selectAll("line")
      .data(data.links)
      .enter()
      .append("line")
      .attr("stroke-width", 1) // Aumentar el tamaño de las líneas
      .attr("stroke", "#999")
      .attr("marker-start", (d) => {
        if (d.direction === "both" || d.direction === "reverse")
          return "url(#arrowhead-reverse)";
        return "";
      })
      .attr("marker-end", (d) => {
        if (d.direction === "both" || d.direction === "forward")
          return "url(#arrowhead-forward)";
        return "";
      });

    // Add weight labels to links
    const linkLabels = svg
      .append("g")
      .attr("class", "link-labels")
      .selectAll("text")
      .data(data.links)
      .enter()
      .append("text")
      .attr("fill", "#606263") // Cambiar el color de las letras
      .attr("font-size", "16px") // Aumentar el tamaño de fuente de los pesos
      .attr("font-weight", "bold") // Aumentar el tamaño de fuente de los pesos
      .attr("dy", -5)
      .text((d) => d.weight);

    const node = svg
      .append("g")
      .attr("class", "nodes")
      .selectAll("circle")
      .data(data.nodes)
      .enter()
      .append("circle")
      .attr("r", 12)
      .attr("fill", "#2C096A") // Cambiar el color de los círculos
      .call(
        d3
          .drag<SVGCircleElement, Node>()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    const nodeLabels = svg
      .append("g")
      .attr("class", "node-labels")
      .selectAll("text")
      .data(data.nodes)
      .enter()
      .append("text")
      .attr("dx", 15)
      .attr("dy", 5)
      .attr("font-size", "16px") // Aumentar el tamaño de fuente de los labels
      .attr("fill", "#606263") // Cambiar el color de las letras
      .text((d) => d.label);

    simulation.on("tick", () => {
      link
        .attr("x1", (d) => (d.source as Node).x!)
        .attr("y1", (d) => (d.source as Node).y!)
        .attr("x2", (d) => (d.target as Node).x!)
        .attr("y2", (d) => (d.target as Node).y!);

      linkLabels
        .attr("x", (d) => ((d.source as Node).x! + (d.target as Node).x!) / 2)
        .attr("y", (d) => ((d.source as Node).y! + (d.target as Node).y!) / 2);

      node.attr("cx", (d) => d.x!).attr("cy", (d) => d.y!);

      nodeLabels.attr("x", (d) => d.x!).attr("y", (d) => d.y!);
    });

    function dragstarted(event: any, d: Node) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event: any, d: Node) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event: any, d: Node) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleGenerateSpeechTree();
    }, 1000);
  }, [svgRef]);

  const children = (
    <div className={style.container_principal_modal_tree}>
      <Title fontSize={25} fontWeight={600}>
        Arbol de discurso
      </Title>
      <svg ref={svgRef}></svg>
      <section className={style.section_btn}>
        <Button
          className="fcdt_btn_without_background_primary"
          onClick={handleCloseModal}
        >
          Atrás
        </Button>
        <Button onClick={handleCloseModal}>Continuar</Button>
      </section>
    </div>
  );

  return (
    <FCDTModal
      open={open}
      children={children}
      handleClose={handleCloseModal}
      width="50%"
      heigth="90%"
    />
  );
};

export default ModalSpeechTree;
