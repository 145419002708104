import { ToogleProps } from './model'
import style from './style/toggle.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setToggleChecked } from 'store/slices/dashboard';
import { Stack, Switch, Typography } from '@mui/material';
export const Toggle = (props: ToogleProps) => {
    const dispatch = useDispatch();
    const { title, titleActive } = props.data
    const { toggleChecked } = useSelector((state: RootState) => {
        return state.dashboard;
    });

    const handleChange = (e: any) => {
        dispatch(setToggleChecked(e.target.value));
    };

    return (
        <div>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography sx={{ fontSize: "20px", fontFamily: 'Poppins', color: !toggleChecked ? '#2F0084' : "" }}>Archivados</Typography>
                <Switch
                    sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            color: '#00DE97',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#00DE97',
                        },
                    }}
                    checked={toggleChecked}
                    onChange={handleChange} name="antoine" />
                <Typography sx={{ fontSize: "20px", fontFamily: 'Poppins', color: !toggleChecked ? "" : '#00DE97' }}>Pendientes</Typography>
            </Stack>

            {/* <div className={style.toggle_container}>
                <input
                    type="checkbox"
                    id="btn"
                    checked={toggleChecked}
                    onChange={handleChange} />
                <label htmlFor="btn"><span className={`${toggleChecked && style.active}`}>{toggleChecked ? titleActive : title}</span></label>
            </div> */}
        </div>
    )
}
